import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import languageProviderReducer from "../../containers/LanguageProvider/reducer";
import globalReducer from "../../globalRedux/reducer";
import authReducer from "../../containers/Authentication/redux/reducer";
import FPXReducer from "../../containers/CPG/redux/reducer";
import loaderReducer from "./loader";

const reducers = combineReducers({
  auth: authReducer,
  form,
  globalReducer,
  language: languageProviderReducer,
  loaderReducer,
  FPXReducer
});

export default reducers;
