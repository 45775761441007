import React, { useEffect, useState } from "react";
import { compose } from "underscore";
import { withStyles } from "@material-ui/core/styles";
import MobileHeader from "../../../components/Header/MobileHeader";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import "./style.scss";
import { withRouter } from "react-router-dom";
import { Checkbox } from "@material-ui/core";
import { connect } from "react-redux";
import Checklist from "../../../assets/images/checklist.svg";
import PrincipalLogo from "../../../assets/images/principal.png";
import NumberFormat from "react-number-format";
import {
  getBankList,
  setInvoiceNo,
  setUserAmount,
  setPayCat,
  eWalletPay,
  getFeeByPayCat,
  setCostumerData
} from "../redux/action";
import { signInAction } from "../../Authentication/redux/actions";
import { DefaultLoader } from "../../../components/Loader";
import { API_URL, isDevSitUat } from "../../../utils/axios";
import { text } from "../../../utils/styles/style";

const styles = {
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column"
  },
  container: {
    padding: "24px"
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    color: text.color.blue,
    fontFamily: "FS-Elliot-Bold, sans-serif"
  },
  paymentList: {
    display: "flex",
    flexDirection: "column"
  },
  button: {
    padding: "1em 2em",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "2rem"
  },
  buttonActive: {
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "2rem",
    borderColor: "#084c95",
    textTransform: "none"
  },
  centered: {
    display: "flex",
    justifyContent: "center"
  },
  titleButton: {
    fontSize: "16px",
    marginLeft: "2rem",
    color: text.color.blue,
    fontWeight: "bold",
    fontFamily: "FS-Elliot-Bold, sans-serif"
  }
};
function PaymentList(props) {
  const invoiceNo = new URLSearchParams(window.location.search).get(
    "invoiceNo"
  );
  let signature = new URLSearchParams(window.location.search).get("Signature");
  // Check if signature is not null before attempting to replace
  if (signature !== null) {
    // "+" character in urlSearchParams is considered whitespace, change the whitespace back to "+" for a valid signature
    signature = signature.replace(/ /g, "+");
  }
  const {
    classes,
    signInAction,
    custData,
    loading,
    getBankList,
    paymentChannel = [],
    paymentCategory,
    setPayCat,
    eWalletPay,
    getFeeByPayCat,
    setCostumerData,
    accessToken
  } = props;
  const [Checked, setChecked] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [paymentChannelList, setpaymentChannelList] = useState([]);

  useEffect(() => {
    setInvoiceNo(invoiceNo);
    signInAction({
      invoiceNo,
      signature,
      checkFrom: "NormalCashIn"
    });
  }, []);

  useEffect(() => {
    manipulateData();
  }, [loading]);

  const manipulateData = () => {
    setpaymentChannelList(() => {
      const newList = paymentChannel.map((value, index) => {
        return { ...value, selected: false };
      });
      if (newList.length) {
        newList[0].selected = true;
      }

      return newList;
    });
  };

  const pay = () => {
    if (paymentCategory.payCatCd === "FPX") {
      getBankList(() => props.history.push("/banklist"));
    } else {
      props.history.push(`/paymentstatus?trxRefNo=${custData.trxRefNo}`);
      const paymentNotifyUrl = `${API_URL.miniProgram}/tng/webhook`;
      const paymentReturnUrl = `${
        isDevSitUat
          ? "https://payuat.principal.com.my"
          : "https://secure05.principal.com.my"
      }/paymentstatus?trxRefNo=${custData.trxRefNo}`;

      const refOrderId = "REF_MERCHANT-" + custData.no;

      const paymentAmount = `${Math.round(custData.amount * 100)}`;
      const payload = {
        trxRefNo: custData.trxRefNo,
        partnerId: "217120000001334440963", //"217120000009895187657",
        paymentNotifyUrl,
        paymentReturnUrl,
        paymentRequestId: refOrderId,
        paymentOrderTitle: "Principal investment platform",
        productCode: "51051000101000100001",
        mcc: "4399",
        paymentAmount: {
          currency: "MYR",
          value: paymentAmount
        },

        paymentFactor: {
          isCashierPayment: true
        },
        extraParams: {
          ORDER: {
            referenceOrderId: refOrderId,
            orderAmount: { currency: "MYR", value: paymentAmount }
          }
        },
        envInfo: {
          //osType: "IOS",
          terminalType: "MINI_APP",
          extendInfo: '{"orderTerminalType":"MINI_APP"}'
        }
      };

      const postInfo = {
        no: custData.no,
        trxRefNo: custData.trxRefNo,
        cifId: custData.cifId ? custData.cifId : "",
        amountCcy: custData.amountCcy,
        feeStatus: "1", //i need to know why feestatus =1
        amount: custData.amount,
        total: parseFloat(custData.amount),
        status: custData.status,
        refId: custData.refId,
        productType: custData.productType ? custData.productType : "",
        targetAmount: parseFloat(custData.amount),
        customerChannelCode: paymentCategory.customerChannelCode,
        payRefNo: custData.payRefNo,
        frequency: 1,
        disburseSts: "2",
        name: custData.name,
        email: custData.email,
        lang: custData.lang,
        resUrl: custData.resUrl,
        backUrl: custData.backUrl,
        sellerId: paymentCategory.sellerId,
        sellerExId: paymentCategory.sellerExId
      };

      eWalletPay(payload, postInfo);
    }
  };

  const ActiveLink = ({ content, link = false }) => {
    return (
      <a
        onClick={e => {
          if (custData.customerChannelCode === "Mini Program" && window.my) {
            e.preventDefault();
            window.my.navigateTo({
              url:
                "/pages/link/link?url=" +
                encodeURIComponent(
                  "https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp"
                )
            });
          }

          if (Checked) {
            setChecked(true);
          } else {
            setChecked(false);
          }
        }}
        href={link}
        className="active-link"
        target={link ? "_blank" : "_self"}
        rel="noopener noreferrer"
      >
        {content}
      </a>
    );
  };

  //two decimal values
  const [totalFee, setTotalFee] = useState(0);
  const [amountDefault, setAmountDefault] = useState(0);
  useEffect(() => {
    if (custData.amount !== undefined && custData.fee !== undefined) {
      setAmountDefault(custData.amount.toFixed(2));
      setTotalFee(custData.amount + custData.fee);
    }
  }, [custData]);

  const getfee = val => {
    getFeeByPayCat({
      channelCode1: val.payCatCd,
      channelCode2: val.customerChannelCode
    });
  };

  return (
    <div className={classes.root}>
      <MobileHeader title="Principal Payment Gateway" />

      <div className={classes.container}>
        <img
          src={PrincipalLogo}
          alt="Principal"
          style={{
            maxWidth: "40%",
            marginBottom: "2rem",
            width: 124.7,
            height: 40
          }}
        />
        {loading ? (
          <div className={classes.centered}>
            <DefaultLoader />
          </div>
        ) : (
          <>
            <div style={{ marginBottom: 12 }}>
              <Typography className={classes.title}>Cash In summary</Typography>
            </div>
            {/* invoice Number:{invoiceNo}  */}
            <div className="blueContainer">
              <div className="descPayment">
                <p>Cash In amount</p>
                <p>
                  {"RM"}{" "}
                  <NumberFormat
                    value={amountDefault}
                    thousandSeparator={true}
                    displayType="text"
                    decimalSeparator="."
                  />
                </p>
              </div>

              <div className="descPayment">
                <p>Adminstration fee</p>
                <p>
                  {"RM"} {parseFloat(custData.fee).toFixed(2) || 0}
                </p>
              </div>

              <div className="descPayment">
                <p className="bolded">Total:</p>
                <p className="bolded">
                  {"RM"}{" "}
                  <NumberFormat
                    value={totalFee.toFixed(2)}
                    thousandSeparator={true}
                    displayType="text"
                    decimalSeparator="."
                    className="bolded"
                  />
                </p>
              </div>
              <Typography
                color="error"
                style={{ fontSize: "1rem", textAlign: "right" }}
              >
                {totalFee > 30000
                  ? `Maximum cash in amount is ${custData.amountCcy} 30,000.00`
                  : null}
              </Typography>
            </div>

            <div style={{ marginTop: 24, marginBottom: 12 }}>
              <Typography className={classes.title}>Cash In method</Typography>
            </div>

            <div className={classes.paymentList}>
              {paymentChannelList &&
                paymentChannelList.map((val, idx) => {
                  return (
                    <Button
                      key={idx}
                      onClick={() => {
                        // dataLayerGTM("gtm.click", "Click", "Button", `${val.payCatCd} ${val.customerChannelCode}`);
                        window.dataLayer.push({
                          event: "click_tag",
                          action: "Click",
                          category: "Button",
                          label: `${val.payCatCd} ${val.customerChannelCode}`
                        });
                        if (!accessToken) {
                          setCostumerData({ fee: 0, amount: 0 });
                          return;
                        }
                        // setChoosen(!Choosen);
                        setPayCat(val);
                        setpaymentChannelList(prevState => {
                          const newList = prevState.map(val => {
                            return { ...val, selected: false };
                          });

                          newList[idx].selected = true;
                          return newList;
                        });
                        getfee(val);
                      }}
                      fullWidth
                      variant="outlined"
                      className={classes.buttonActive}
                      color="primary"
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={require(`../../../assets/images/${
                            val.imageUrl
                          }`)}
                          // img source null will make error (val.imgUrl)
                          // src=""
                          alt="FPX"
                          className="FPXLogo"
                        />

                        <Typography className={classes.titleButton}>
                          {val.description}
                        </Typography>
                      </div>

                      {val.selected ? (
                        <img
                          src={Checklist}
                          alt="checklist"
                          className="checklistSVG"
                        />
                      ) : null}
                    </Button>
                  );
                })}
            </div>

            {paymentCategory.payCatCd === "FPX" ? (
              <>
                <div className="checkBoxes">
                  <div className="checkfield">
                    <Checkbox
                      checked={Checked}
                      onChange={() => {
                        setChecked(!Checked);
                      }}
                      color="primary"
                      value="checkedA"
                      className="checkBoxTandC"
                    />
                  </div>
                  <div
                    className="TandC"
                    onClick={() => {
                      setChecked(!Checked);
                    }}
                  >
                    <p style={{ color: text.color.grey }}>
                      I have read and agreed to
                      <ActiveLink
                        link="https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp"
                        content=" Payment Terms & Conditions "
                      />
                    </p>
                  </div>
                </div>
                <div className="text-as">
                  <p style={{ color: text.color.grey }}>
                    For Online banking, Cash In requires a single bank account
                    under your name. Using another person's account will result
                    in rejection.
                  </p>
                </div>
                <div className="SubmitButton">
                  <Button
                    disabled={
                      !Checked ||
                      parseFloat(totalFee) < 10 ||
                      parseFloat(totalFee) > 30000
                      //|| totalFee >= 30000.55
                    }
                    onClick={() => {
                      window.dataLayer.push({
                        event: "click_tag",
                        action: "Click",
                        category: "Button",
                        label: "Next Button"
                      });
                      //onsole.log(window.dataLayer);
                      pay();
                    }}
                    variant="contained"
                    color="secondary"
                    className="next-button"
                    fullWidth
                  >
                    Next
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="SubmitButton">
                  <Button
                    onClick={() => {
                      window.dataLayer.push({
                        event: "click_tag",
                        action: "Click",
                        category: "Button",
                        label: "Next Button"
                      });
                      //console.log(window.dataLayer);
                      pay();
                    }}
                    variant="contained"
                    color="secondary"
                    className="next-button"
                    fullWidth
                  >
                    Next
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

const withConnect = connect(
  state => ({
    ...state.FPXReducer,
    ...state.auth
  }),
  {
    setUserAmount,
    signInAction,
    getBankList,
    setInvoiceNo,
    setPayCat,
    eWalletPay,
    getFeeByPayCat,
    setCostumerData
  }
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect
)(PaymentList);
