import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { compose } from "underscore";
import MobileHeader from "../../../components/Header/MobileHeader";
import "./style.scss";
import { connect } from "react-redux";
import { List, ListItem } from "@material-ui/core";
import { withRouter } from "react-router";

// action
import {
  setSelectedBank,
  postPaymentRequest,
  setCheckoutLoading
} from "../redux/action";
import moment from "moment";
import { text } from "../../../utils/styles/style";
import { DefaultLoader } from "../../../components/Loader";

const styles = {
  root: {
    flexGrow: 1,
    height: "640px"
  },
  cardBank: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 64,
    cursor: "pointer"
  },
  title: {
    marginLeft: "2rem",
    flex: 1,
    fontSize: "12px",
    fontFamily: "FS-Elliot, sans-serif"
  },
  BankName: {
    marginLeft: "2rem",
    flex: 1,
    fontSize: "16px",
    fontFamily: "FS-Elliot, sans-serif"
  },
  leftBar: {
    height: "4em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  centered: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  BankListTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    fontFamily: "FS-Elliot, sans-serif"
  },
  statusBank: {
    fontSize: "16px",
    textAlign: "right",
    fontFamily: "FS-Elliot, sans-serif"
  }
};

function BankList(props) {
  const {
    classes,
    isFPXOffline,
    bankList,
    postPaymentRequest,
    custData,
    paymentCategory,
    paymentChannel,
    loading,
    setCheckoutLoading,
    checkoutLoading
  } = props;

  useEffect(() => {
    setCheckoutLoading(false);
  }, []);

  const paymentRequest = value => {
    props.setSelectedBank({
      bankName: value.fpxBankName,
      bankLogo: value.fpxBankLogo,
      time: moment(new Date()).format("DD/MM/YYYY hh:mm:ss")
    });
    postPaymentRequest({
      no: custData.no,
      trxRefNo: custData.trxRefNo,
      cifId: custData.cifId ? custData.cifId : "",
      fee: parseFloat(value.fee),
      feeCcy: value.feeCcy,
      custFee: parseFloat(value.custFee),
      custFeeCcy: value.custFeeCcy.toString(),
      absFeeCcy: value.absFeeCcy.toString(),
      absFee: parseFloat(value.absFee),
      refundFee: parseFloat(value.refundFee),
      amountCcy: custData.amountCcy,
      feeStatus: "1",
      amount: custData.amount,
      total: parseFloat(custData.amount) + parseFloat(value.fee),
      payCatCd: value.payCatCd,
      payTypeCd: value.code,
      status: custData.status,
      refId: custData.refId,
      productType: custData.productType ? custData.productType : "",
      targetAmount: parseFloat(custData.amount) + parseFloat(value.fee),
      customerChannelCode: paymentCategory.customerChannelCode,
      payRefNo: custData.payRefNo,
      frequency: 1,
      disburseSts: "2",
      name: custData.name,
      email: custData.email,
      lang: custData.lang,
      resUrl: custData.resUrl,
      backUrl: custData.backUrl,
      sellerId: paymentCategory.sellerId,
      sellerExId: paymentCategory.sellerExId
    });
  };

  const RenderBankList = () => {
    return (
      <>
        {bankList &&
          bankList.map((value, index) => {
            return (
              <ListItem
                disabled={value.bankSts === "A" ? false : true}
                onClick={() => {
                  paymentRequest(value);
                  window.dataLayer.push({
                    event: "click_tag",
                    action: "Click",
                    category: "Link",
                    label: `${value.name} ${value.channelCd}`
                  });
                }}
                key={index}
                className={classes.cardBank}
              >
                <img
                  className="paymentMethodImage"
                  src={require(`../../../assets/images/banks/${value.logo}`)}
                  alt="bank"
                />
                <Typography className={classes.BankName} component="p">
                  {value.name}
                </Typography>

                {value.bankSts === "A" ? (
                  <div>
                    <i className="fas fa-chevron-right" />
                  </div>
                ) : (
                  <Typography className={classes.statusBank} component="p">
                    Offline
                  </Typography>
                )}
              </ListItem>
            );
          })}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <MobileHeader title="Principal Payment Gateway" />
      {loading || checkoutLoading ? (
        <div className={classes.centered}>
          <DefaultLoader />
        </div>
      ) : (
        <>
          <div className="blueContainer fixedPostition">
            <div className="insideContainer">
              <i
                style={{ color: text.color.darkBlue }}
                className={`fas ${
                  isFPXOffline ? "fa-exclamation-triangle" : "fa-info-circle"
                } logo`}
              />
              <Typography
                color="primary"
                className="warningStatus"
                component="p"
              >
                {isFPXOffline
                  ? "FPX is currently not available, please try again later."
                  : "Please Cash In with a bank account under your name"}
              </Typography>
            </div>
          </div>

          <div style={{ padding: "4px 24px" }}>
            <Typography color="primary" className={classes.BankListTitle}>
              Please select your preferred bank
            </Typography>
          </div>
          <List>
            <RenderBankList />
          </List>
        </>
      )}
    </div>
  );
}

const withConnect = connect(
  state => ({
    ...state.FPXReducer
  }),
  { setSelectedBank, postPaymentRequest, setCheckoutLoading }
);

export default compose(
  withStyles(styles),
  withConnect,
  withRouter
)(BankList);
