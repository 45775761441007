// Place here all endpoint addresses that app makes api calls to.
// for example:
// export const SIGN_UP_ENDPOINT = '/api/signup';
export const SIGN_IN_ENDPOINT = "/ppg/login";
export const SIGN_UP_ENDPOINT = "";
export const REFRESH_ACCESS_TOKEN_ENPOINT = "";
export const TEST_AUTH_ENDPOINT = "";
export const ALL_CUSTOMERS_ENDPOINT =
  "/customer/api/v1/customers?fields=documentStatusCode,enhancedKyc,firstNameEn,lastNameEn,firstNameTh,lastNameTh,fullName,id,kycSuccess,mobile,reviewAt,riskLevel,statusCode,submissionAt,unitholderId,uuid&";
export const GET_CLIENT_DETAIL_ENDPOINT =
  "?fields=bank{accountName,accountNo,bankCode},children{firstName,birthDate,fullName,identificationNo,lastName},contactAddress{address1,address2,countryCode,districtCode,postCode,provinceCode,subDistrict},detail{avatar{url},birthDate,email,firstName,firstNameTh,lastNameTh,lastName,genderCode,identificationExpireDate,identificationNo,identificationTypeCode,lazerCode,maritalStatusCode,mobile,nationalityCode,taxOption},occupation{businessOther,incomeCode,occupationCode,occupationOther,sourceOfFundCode,sourceOfIncomeCode},residentialAddress{address1,address2,countryCode,districtCode,postCode,provinceCode,subDistrict},risk{level},spouse{firstName,fullName,identificationNo,lastName},statusCode,workplaceAddress{address1,address2,countryCode,districtCode,name,postCode,provinceCode,subDistrict}";
export const GET_DOCUMENTS_BY_ID_ENDPOINT =
  "/documents?fields=lastModifiedAt%2CstatusCode%2CtypeCode%2Curl%2Cuuid";
export const ALL_FUNDS_ENDPOINT = "/funds/api/v1/dashboard/funds";
export const POST_CSV_DATA = "/api/v1/dashboard/";
export const GET_FUNDS_BY_ID = "/admin/api/admin-actions/getPortfolioByUserId";
export const GET_IS_LOCKED_STATUS = "/admin/api/admin-actions/getLockoutStatus";
export const POST_UNLOCKED_STATUS = "/admin/api/admin-actions/unlockUser";
export const EXISTING_FUND = "/funds/api/v1/dashboard/fund/fundCode/exists";
export const RECEIVE_FUND_DETAIL_EXISTING = "/funds/api/v1/dashboard/fund/";
export const SAVE_FUND = "/funds/api/v1/dashboard/fund/";
export const PATCH_UPDATE_IMAGE = "/funds/api/v1/dashboard/fund/";
export const GET_TRANSACTION_DETAILS =
  "/order/api/v1/orders?fields=amount%2Cchannel%2CeffectiveDate%2Cfee%2CfundCode%2CfundNo%2Cnav%2CprofitLoss%2CstatusCode%2CstatusDesc%2Ctax%2CtoFundCode%2CtoFundNo%2CtransCode%2CtransDatetime%2CtransNo%2Cunit%2CunitholderId%2CunitholderName&";
export const FUND_CATEGORIES = "/funds/api/v1/getUniqueFundCategory";
