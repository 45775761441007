import axios from "axios";

const env = process.env.REACT_APP_ENV.trim();
export const isDevSitUat =
  env === "development" || env === "sit" || env === "uat";

export const API_URL = {
  ppg: isDevSitUat
    ? process.env.REACT_APP_PPG_UAT.trim()
    : process.env.REACT_APP_PPG_PROD.trim(),
  payment: "https://empty-dragonfly-53.loca.lt/api/v1",
  miniProgram: isDevSitUat
    ? process.env.REACT_APP_MP_UAT.trim()
    : process.env.REACT_APP_MP_PROD.trim()
};

var instance = null;

function makeInstance() {
  if (instance) {
    return instance;
  } else {
    instance = axios.create({
      baseURL: API_URL.ppg,
      timeout: 20000
    });
    return instance;
  }
}

var axiosInstance = makeInstance();

export const setAuthHeader = accessToken => {
  // axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
};

export const resetAuthHeader = () => {
  delete axiosInstance.defaults.headers.common.Authorization;
};

export default axiosInstance;
