/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import "babel-polyfill";

// Import all the third party stuff
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import App from "./containers/App";

// Import Language Provider
import { LanguageProvider } from "./containers/LanguageProvider";

import configureStore from "./store/index";

// Import i18n messages
import { translationMessages } from "./i18n";

// Import CSS reset and Global Styles
import GlobalStyle from "./global-styles";

import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import "./main.scss";
import "./components/input/styles.scss";
//
const initialState = {};
const { persistor, store } = configureStore(initialState);
const MOUNT_NODE = document.getElementById("root");

const state = store.getState();

const { EventEmitter } = require("fbemitter");
window.eventEmitter = new EventEmitter();

const render = messages => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages} locale={state.language.locale}>
        <PersistGate persistor={persistor}>
          <App />
          <GlobalStyle />
        </PersistGate>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(["./i18n", "./containers/App"], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import("intl"));
  })
    .then(() => Promise.all([import("intl/locale-data/jsonp/en.js")]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === "production") {
  require("offline-plugin/runtime").install(); // eslint-disable-line global-require
}
