/*
 *
 * LanguageProvider constants
 *
 */

export const CHANGE_LOCALE = "app/LanguageToggle/CHANGE_LOCALE";

export const EN_LOCALE = "en";
export const THAI_LOCALE = "th";
export const DEFAULT_LOCALE = EN_LOCALE;
