/*
 *Function that helps us to customize prompt from react-router-dom  when leave page "PreventNavigationDialog"
 * */
export const getUserConfirmation = (dialogKey, callback) => {
  const dialogTrigger = window[Symbol.for(dialogKey)];
  if (dialogTrigger) {
    return dialogTrigger(callback);
  } else {
    callback(window.confirm(dialogKey));
  }
};
