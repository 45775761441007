import React, { useState, useEffect } from "react";
import style from "./style";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "underscore";
import MobileHeader from "../../../components/Header/MobileHeader";
import PrincipalLogo from "../../../assets/images/principal.png";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import { signInAction } from "../../Authentication/redux/actions";
import { getRspStatus, setRspStatus } from "../redux/action";
import moment from "moment";

function RspStatus(props) {
  const trxRefNumber = new URLSearchParams(window.location.search).get(
    "trxRefNo"
  );
  let signature = new URLSearchParams(window.location.search).get("signature");
  // Check if signature is not null before attempting to replace
  if (signature !== null) {
    // "+" character in urlSearchParams is considered whitespace, change the whitespace back to "+" for a valid signature
    signature = signature.replace(/ /g, "+");
  }
  const { classes, getRspStatus, rspStatus, loading, signInAction } = props;
  const ActiveLink = ({ content, link = false }) => {
    return (
      <a
        onClick={e => {
          e.preventDefault();
          window.my.navigateTo({
            url:
              "/pages/link/link?url=" +
              encodeURIComponent("https://www.principal.com.my/en/contact-us")
          });
        }}
        href={link}
        className="active-link"
        target={link ? "_blank" : "_self"}
        rel="noopener noreferrer"
      >
        {content}
      </a>
    );
  };

  const ordinal = number => {
    switch (number) {
      case 1:
      case 21:
      case 31:
        return number + "st";
      case 2:
      case 22:
        return number + "nd";
      case 3:
      case 23:
        return number + "rd";
      default:
        return number + "th";
    }
  };

  useEffect(() => {
    signInAction({ trxRefNumber: trxRefNumber, signature, checkFrom: "RSP" });
  }, []);

  const [progress, setProgress] = useState(0);
  const handlega = () => {
    if (rspStatus.status === "5") {
      window.dataLayer.push({
        event: "click_tag",
        action: "Click",
        category: "Button",
        label: `RSP Success`
      });
    } else {
      window.dataLayer.push({
        event: "click_tag",
        action: "Click",
        category: "Button",
        label: `RSP Failed`
      });
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!rspStatus.status || rspStatus.status === 1) {
        getRspStatus(trxRefNumber);
      }
      setProgress(progress => progress + 1);
    }, 1000);
    if (progress === 3) {
      clearTimeout(timeout);
    }

    return () => clearTimeout(timeout);
  }, [progress, rspStatus.status]);

  return (
    <>
      <div className={classes.App}>
        <MobileHeader title="Principal Payment Gateway" />
        {loading ? (
          <>
            {progress < 3 ? (
              <div className={classes.centered} style={{ flex: 1 }}>
                <img
                  src={PrincipalLogo}
                  alt="Principal"
                  style={{
                    maxWidth: "40%",
                    marginBottom: "16px",
                    width: 124.7,
                    height: 40
                  }}
                />
                <Typography className={classes.title}>
                  Please Wait ...
                </Typography>
                <p />
                <Typography className={classes.titleCenter}>
                  We are processing your Cash In
                </Typography>
              </div>
            ) : (
              <>
                <div style={{ padding: "0 2rem", marginTop: 16 }}>
                  <img
                    style={{
                      maxWidth: "40%",
                      marginBottom: "16px",
                      width: 124.7,
                      height: 40
                    }}
                    src={PrincipalLogo}
                    alt="principal-logo"
                  />

                  <Typography
                    className={classes.titleProgress}
                    style={{
                      marginBottom: 20
                    }}
                  >
                    Auto Cash In request for processing...
                  </Typography>
                  <Typography
                    style={{
                      wordSpacing: "2px",
                      fontSize: "16px",
                      color: "1D1D1D",
                      fontFamily: "FSElliot, sans-serif",
                      margin: "16px 0 23px"
                    }}
                    component="p"
                  >
                    {`Your Auto Cash In request is still pending, please check your email for the Auto Cash In status after 15 to 30 minutes. For assitance, please contact our`}
                    {
                      <ActiveLink
                        link="https://www.principal.com.my/en/contact-us"
                        content=" customer support"
                      >
                        customer support
                      </ActiveLink>
                    }
                  </Typography>
                  <div
                    className={classes.row}
                    style={{
                      justifyContent: "space-between",
                      padding: "0 0 24px"
                      //borderBottom: "1px solid #9d9ea4"
                    }}
                  >
                    <Typography
                      color="textPrimary"
                      className={classes.baseFont}
                    >
                      Auto Cash In date
                    </Typography>
                    <Typography
                      color="textPrimary"
                      className={classes.baseFont}
                    >
                      {ordinal(parseInt(rspStatus.dateDeposit))}{" "}
                      {"of each month"}
                    </Typography>
                  </div>
                  <div
                    className={classes.row}
                    style={{
                      justifyContent: "space-between",
                      padding: "0 0 24px",
                      borderBottom: "1px solid #9d9ea4"
                    }}
                  >
                    <Typography
                      color="textPrimary"
                      className={classes.baseFont}
                    >
                      Auto Cash In amount
                    </Typography>
                    <Typography
                      color="textPrimary"
                      className={classes.baseFont}
                    >
                      RM{" "}
                      <NumberFormat
                        value={rspStatus.total}
                        thousandSeparator
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </Typography>
                  </div>
                </div>
                <div style={{ margin: "16px 16px" }}>
                  <div
                    className={classes.row}
                    style={{
                      justifyContent: "space-between",
                      marginBottom: "18px"
                    }}
                  >
                    <Typography
                      color="textPrimary"
                      className={classes.baseFont}
                    >
                      Pre-authorisation fee
                    </Typography>
                    <Typography
                      color="textPrimary"
                      className={classes.baseFont}
                    >
                      RM{" "}
                      <NumberFormat
                        value={rspStatus.fee || "1"}
                        thousandSeparator
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </Typography>
                  </div>
                  <div
                    className={classes.row}
                    style={{
                      justifyContent: "space-between",
                      marginBottom: "18px"
                    }}
                  >
                    <Typography
                      color="textPrimary"
                      className={classes.baseFont}
                    >
                      Cash In Via
                    </Typography>
                    <Typography
                      color="textPrimary"
                      className={classes.baseFont}
                    >
                      {rspStatus.payCatCd}
                    </Typography>
                  </div>
                  <div
                    className={classes.row}
                    style={{
                      justifyContent: "space-between",
                      marginBottom: "18px"
                    }}
                  >
                    <Typography
                      color="textPrimary"
                      className={classes.baseFont}
                    >
                      Date
                    </Typography>
                    <Typography
                      color="textPrimary"
                      className={classes.baseFont}
                    >
                      {moment(rspStatus.pgTrxTime || new Date())
                        .utcOffset(8)
                        .format("DD MMM YYYY")}
                    </Typography>
                  </div>
                  <div
                    className={classes.row}
                    style={{
                      justifyContent: "space-between",
                      marginBottom: "18px"
                    }}
                  >
                    <Typography
                      color="textPrimary"
                      className={classes.baseFont}
                    >
                      Time
                    </Typography>
                    <Typography
                      color="textPrimary"
                      className={classes.baseFont}
                    >
                      {moment(rspStatus.pgTrxTime || new Date())
                        .utcOffset(8)
                        .format("hh:mm:ss A")}
                    </Typography>
                  </div>
                  <div
                    className={classes.row}
                    style={{
                      justifyContent: "space-between",
                      marginBottom: "18px"
                    }}
                  >
                    <Typography
                      color="textPrimary"
                      className={classes.baseFont}
                    >
                      Reference number
                    </Typography>
                    <Typography
                      color="textPrimary"
                      className={classes.baseFont}
                    >
                      {rspStatus.trxRefNo}
                    </Typography>
                  </div>
                  <div
                    className={classes.row}
                    style={{
                      justifyContent: "space-between",
                      marginBottom: "18px"
                    }}
                  >
                    <Typography
                      color="textPrimary"
                      className={classes.baseFont}
                    >
                      FPX Transaction ID
                    </Typography>
                    <Typography
                      color="textPrimary"
                      className={classes.baseFont}
                    >
                      {rspStatus.payRefNo}
                    </Typography>
                  </div>

                  <div
                    className={classes.row}
                    style={{
                      justifyContent: "space-between",
                      marginTop: "16px"
                    }}
                  >
                    <Typography
                      className={classes.baseFont}
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        lineHeight: 1.4,
                        color: "#084c95"
                      }}
                    >
                      Total:
                    </Typography>
                    <Typography
                      className={classes.baseFont}
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        lineHeight: 1.4,
                        color: "#084c95"
                      }}
                    >
                      RM{" "}
                      <NumberFormat
                        value={rspStatus.fee || "1"}
                        thousandSeparator
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </Typography>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0 20px",
                      marginTop: 24
                    }}
                    className="SubmitButton"
                  >
                    <Button
                      onClick={() => {
                        window.location.replace(rspStatus.resUrl);
                        handlega();
                        // window.location.replace(
                        //   "https://mpuat.principal.com.my/dashboard"
                        // );
                        //postToChannel(rspStatus);
                        //console.log(postToChannel);
                      }}
                      variant="contained"
                      color="secondary"
                      className="next-button"
                      fullWidth
                      style={{ marginBottom: 24 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div style={{ padding: "0 2rem", marginTop: 16 }}>
              <img
                style={{
                  maxWidth: "40%",
                  marginBottom: "16px",
                  width: 124.7,
                  height: 40
                }}
                src={PrincipalLogo}
                alt="principal-logo"
              />

              <Typography
                className={
                  rspStatus.status === "5" ? classes.title : classes.titleFailed
                }
                style={{
                  marginBottom: 20
                }}
              >
                {rspStatus.status === "5"
                  ? "Monthly auto Cash In submitted for processing..."
                  : "Monthly auto Cash In failed"}
              </Typography>
              <Typography
                style={{
                  wordSpacing: "2px",
                  fontSize: "16px",
                  color: "1D1D1D",
                  fontFamily: "FSElliot, sans-serif",
                  margin: "16px 0 23px"
                }}
                component="p"
              >
                {rspStatus.status === "5"
                  ? "Your monthly auto Cash In request will be processed within 2 business days. Thank you."
                  : rspStatus.status === "7"
                  ? `Auto Cash In was made not using your own bank account. Registration fee will be refunded within 3 working days. For more details, please contact our `
                  : `Sorry, we were unable to process the Cash In request. Please try again or contact our `}
                {rspStatus.status === "5" ? null : (
                  <ActiveLink
                    link="https://www.principal.com.my/en/contact-us"
                    content=" customer support"
                  >
                    customer support
                  </ActiveLink>
                )}
              </Typography>
              <div
                className={classes.row}
                style={{
                  justifyContent: "space-between",
                  padding: "0 0 24px"
                  //borderBottom: "1px solid #9d9ea4"
                }}
              >
                <Typography color="textPrimary" className={classes.baseFont}>
                  Auto Cash In date
                </Typography>
                <Typography color="textPrimary" className={classes.baseFont}>
                  {ordinal(parseInt(rspStatus.dateDeposit))} {"of each month"}
                </Typography>
              </div>
              <div
                className={classes.row}
                style={{
                  justifyContent: "space-between",
                  padding: "0 0 24px",
                  borderBottom: "1px solid #9d9ea4"
                }}
              >
                <Typography color="textPrimary" className={classes.baseFont}>
                  Auto Cash In amount
                </Typography>
                <Typography color="textPrimary" className={classes.baseFont}>
                  RM{" "}
                  <NumberFormat
                    value={rspStatus.total}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </Typography>
              </div>
            </div>
            <div style={{ margin: "16px 16px" }}>
              <div
                className={classes.row}
                style={{
                  justifyContent: "space-between",
                  marginBottom: "18px"
                }}
              >
                <Typography color="textPrimary" className={classes.baseFont}>
                  Pre-authorisation fee
                </Typography>
                <Typography color="textPrimary" className={classes.baseFont}>
                  RM{" "}
                  <NumberFormat
                    value={rspStatus.fee || "1"}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </Typography>
              </div>
              <div
                className={classes.row}
                style={{
                  justifyContent: "space-between",
                  marginBottom: "18px"
                }}
              >
                <Typography color="textPrimary" className={classes.baseFont}>
                  Cash Out Via
                </Typography>
                <Typography color="textPrimary" className={classes.baseFont}>
                  {rspStatus.payCatCd}
                </Typography>
              </div>
              <div
                className={classes.row}
                style={{
                  justifyContent: "space-between",
                  marginBottom: "18px"
                }}
              >
                <Typography color="textPrimary" className={classes.baseFont}>
                  Date
                </Typography>
                <Typography color="textPrimary" className={classes.baseFont}>
                  {moment(rspStatus.pgTrxTime || new Date())
                    .utcOffset(8)
                    .format("DD MMM YYYY")}
                </Typography>
              </div>
              <div
                className={classes.row}
                style={{
                  justifyContent: "space-between",
                  marginBottom: "18px"
                }}
              >
                <Typography color="textPrimary" className={classes.baseFont}>
                  Time
                </Typography>
                <Typography color="textPrimary" className={classes.baseFont}>
                  {moment(rspStatus.pgTrxTime || new Date())
                    .utcOffset(8)
                    .format("hh:mm:ss A")}
                </Typography>
              </div>
              <div
                className={classes.row}
                style={{
                  justifyContent: "space-between",
                  marginBottom: "18px"
                }}
              >
                <Typography color="textPrimary" className={classes.baseFont}>
                  Reference number
                </Typography>
                <Typography color="textPrimary" className={classes.baseFont}>
                  {rspStatus.trxRefNo}
                </Typography>
              </div>
              <div
                className={classes.row}
                style={{
                  justifyContent: "space-between",
                  marginBottom: "18px"
                }}
              >
                <Typography color="textPrimary" className={classes.baseFont}>
                  FPX Transaction ID
                </Typography>
                <Typography color="textPrimary" className={classes.baseFont}>
                  {rspStatus.payRefNo}
                </Typography>
              </div>

              <div
                className={classes.row}
                style={{ justifyContent: "space-between", marginTop: "16px" }}
              >
                <Typography
                  className={classes.baseFont}
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    lineHeight: 1.4,
                    color: "#084c95"
                  }}
                >
                  Total:
                </Typography>
                <Typography
                  className={classes.baseFont}
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    lineHeight: 1.4,
                    color: "#084c95"
                  }}
                >
                  RM{" "}
                  <NumberFormat
                    value={rspStatus.fee || "1"}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </Typography>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0 20px",
                  marginTop: 24
                }}
                className="SubmitButton"
              >
                <Button
                  onClick={() => {
                    window.location.replace(rspStatus.resUrl);
                    handlega();
                  }}
                  variant="contained"
                  color="secondary"
                  className="next-button"
                  fullWidth
                  style={{ marginBottom: 24 }}
                >
                  Next
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
const withConnect = connect(
  state => ({
    ...state.FPXReducer,
    loadingData: state.globalReducer.loadingReducer.isLoading
  }),
  { getRspStatus, setRspStatus, signInAction }
);
export default compose(
  withStyles(style),
  withRouter,
  withConnect
)(RspStatus);
