import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import { compose } from "underscore";
import { withRouter } from "react-router";
import { Modal } from "react-bootstrap";
import "../style.scss";
import { setCanceledData } from "../../../containers/CPG/redux/action";
import { connect } from "react-redux";
import { WbIncandescentOutlined } from "@material-ui/icons";

const styles = {
  AppBar: {
    height: "4em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 24px",
    backgroundColor: "#084c95",
    color: "white"
  },
  leftBar: {
    height: "4em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  title: {
    color: "white",
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: "FS-Elliot, sans-serif"
  },
  titleNoBack: {
    color: "white",
    fontSize: "16px",
    fontWeight: "bold",
    fontFamily: "FS-Elliot, sans-serif"
  },
  Close: {
    color: "white",
    fontSize: "16px",
    fontWeight: 100,
    fontFamily: "FS-Elliot, sans-serif",
    cursor: "pointer"
  }
};

function MobileHeader(props) {
  const { classes, title, noBack = false, setCanceledData = () => {} } = props;
  const [isShow, setIsShow] = useState(false);

  return (
    <AppBar className={classes.AppBar} position="static" color="default">
      <div className={classes.leftBar}>
        {/* {noBack ? null : (
          <i
            onClick={() => {
              props.history.goBack();
            }}
            className="fas fa-arrow-left fa-lg"
          />
        )} */}
        <Typography
          className={noBack ? classes.titleNoBack : classes.title}
          component="p"
        >
          {title}
        </Typography>
      </div>
      {window.location.pathname === "/paymentstatus" ||
      window.location.pathname === "/temppaymentstatus" ||
      window.location.pathname === "/rspstatus" ||
      window.location.pathname === "/popup" ? (
        <></>
      ) : (
        <Typography
          onClick={() => {
            setIsShow(true);
          }}
          className={classes.Close}
          component="p"
        >
          Close
        </Typography>
      )}

      <Modal {...props} bsClass="modalCentered" show={isShow}>
        <div className="modal-close-container">
          <Typography style={{ fontSize: "16px" }} component="p">
            Do you want to cancel this Cash In?
          </Typography>
          <div className="modalConfirmation">
            <Typography
              className="CancelConfirmation"
              color="primary"
              component="a"
              onClick={e => {
                e.preventDefault();
                setIsShow(false);
              }}
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              No
            </Typography>
            <Typography
              color="primary"
              component="a"
              onClick={e => {
                e.preventDefault();
                setIsShow(false);
                //console.log(props, "from header");
                setCanceledData({ time: new Date() });
                props.history.push("/paymentstatus?isCancel=true");
              }}
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              Yes
            </Typography>
          </div>
        </div>
      </Modal>
    </AppBar>
  );
}

const withConnect = connect(
  state => ({
    ...state.FPXReducer
  }),
  {
    setCanceledData
  }
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect
)(MobileHeader);
