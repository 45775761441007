import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { setCanceledData } from "../../../containers/CPG/redux/action";
import "./style.scss";
import MobileHeader from "../../../components/Header/MobileHeader";
import PrincipalLogo from "../../../assets/images/principal.png";
import { Typography } from "@material-ui/core";
import moment from "moment";
import {
  getPaymentStatus,
  setPaymentStatus,
  postToChannel
} from "../redux/action";
import { formatCurrency } from "../../../utils/general";
import { text } from "../../../utils/styles/style";
import { ImportantUrl } from "../../../utils/importantUrl";
import { signInAction } from "../../Authentication/redux/actions";
import { DefaultLoader } from "../../../components/Loader";
import { right } from "@popperjs/core";
import NumberFormat from "react-number-format";

const styles = {
  App: {
    flexGrow: 1,
    //height: "100vh",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "4vh"
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#00a29b",
    fontFamily: "FS-Elliot-Bold, sans-serif"
  },
  titleCenter: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#00a29b",
    textAlign: "center",
    fontFamily: "FS-Elliot-Bold, sans-serif"
  },
  titleFailed: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#c00000",
    fontFamily: "FS-Elliot-Bold, sans-serif"
  },
  centered: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
};

function PaymentConfirmation(props) {
  const trxRefNumber = new URLSearchParams(window.location.search).get(
    "trxRefNo"
  );

  const {
    classes,
    getPaymentStatus,
    paymentStatus = { status: "1" },
    postToChannel,
    custData
  } = props;

  // eslint-disable-next-line no-unused-vars

  const [totalStatus, setotalStatus] = useState(0);

  const ActiveLink = ({ content, link = false }) => {
    return (
      <a
        onClick={e => {
          if (custData.customerChannelCode === "Mini Program" && window.my) {
            e.preventDefault();
            window.my.navigateTo({
              url:
                "/pages/link/link?url=" +
                encodeURIComponent("https://www.principal.com.my/en/contact-us")
            });
          }
        }}
        href={link}
        className="active-link"
        target={link ? "_blank" : "_self"}
        rel="noopener noreferrer"
      >
        {content}
      </a>
    );
  };

  useEffect(() => {
    getPaymentStatus(trxRefNumber);
  }, []);

  useEffect(() => {
    setotalStatus(paymentStatus.total + paymentStatus.fee);
  }, [paymentStatus.fee]);

  return (
    <>
      <div className={classes.App}>
        <MobileHeader title="Principal Payment Gateway" />
        <div style={{ padding: "0 2rem", marginTop: 16 }}>
          <img
            src={PrincipalLogo}
            alt="Principal"
            style={{
              maxWidth: "40%",
              marginBottom: "16px",
              width: 124.7,
              height: 40
            }}
          />
          <Typography
            style={{ marginBottom: 20 }}
            className={classes.titleFailed}
          >
            {"Cash In failed"}
          </Typography>
          <Typography
            style={{
              wordSpacing: "2px",
              fontSize: "16px",
              color: text.color.greyText,
              fontFamily: "FS-Elliot, sans-serif"
            }}
            component="p"
          >
            {`Sorry,  we were unable to process the Cash In. Please try again or contact our `}
            {
              <ActiveLink
                link="https://www.principal.com.my/en/contact-us"
                content=" customer support"
              >
                customer support.
              </ActiveLink>
            }
          </Typography>
        </div>
        <div style={{ padding: "0 2rem" }}>
          <div className="doted space-between" style={{ margin: "20px 0" }} />
          <div className="paymentStatus-container">
            <div className="deposit-via-info">
              <p className="status-label">Cash In amount</p>
              <p className="">
                {"RM"}{" "}
                <NumberFormat
                  value={parseFloat(custData.amount).toFixed(2)}
                  thousandSeparator={true}
                  displayType="text"
                  decimalSeparator="."
                />
              </p>
            </div>
            <div className="deposit-via-info">
              <p>Administrative fee</p>
              <p className="">
                {"RM"} {parseFloat(paymentStatus.fee).toFixed(2)}
              </p>
            </div>
            <div className="deposit-via-info">
              <p className="status-label">Cash In via</p>
              <p className="">{paymentStatus.payCatDesc || "No Data"}</p>
            </div>
            <div className="deposit-via-info">
              <p className="status-label">Date</p>

              <p className="">
                {moment(paymentStatus.pgTrxTime || new Date())
                  .utcOffset(8)
                  .format("DD MMM YYYY")}
              </p>
            </div>
            <div className="deposit-via-info">
              <p className="status-label">Time</p>
              <p className="">
                {moment(paymentStatus.pgTrxTime || new Date())
                  .utcOffset(8)
                  .format("hh:mm:ss A")}
              </p>
            </div>
            <div className="deposit-via-info">
              <p>Reference no</p>
              <p className="ref-no">{paymentStatus.pgRefNo || "No Data"}</p>
            </div>
            <div className="deposit-via-info">
              <p>Transaction no</p>
              <p className="ref-no">{paymentStatus.trxRefNo || "No Data"}</p>
            </div>
            <div className="deposit-via-info">
              {paymentStatus.customerChannelCode === "Mini Program" ? (
                <p className="status-label">
                  Touch'n Go eWallet Transaction Id
                </p>
              ) : (
                <p className="status-label">FPX Transaction Id</p>
              )}
              <p className="trx-id">{paymentStatus.payRefNo || "Inprogress"}</p>
            </div>

            <div className="deposit-via-info">
              <p className="bolded total" style={{ color: text.color.blue }}>
                Total :
              </p>
              <p style={{ color: text.color.blue }} className="bolded total">
                {"RM"}{" "}
                <NumberFormat
                  value={totalStatus.toFixed(2)}
                  thousandSeparator={true}
                  displayType="text"
                  decimalSeparator="."
                  className="bolded total"
                />
              </p>
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0 20px",
              marginTop: 24
            }}
            className="SubmitButton"
          >
            <Button
              onClick={() => {
                postToChannel(paymentStatus);
              }}
              variant="contained"
              color="secondary"
              className="next-button"
              fullWidth
              style={{ marginBottom: 24 }}
            >
              Done
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

const withConnect = connect(
  state => ({
    ...state.FPXReducer,
    loadingData: state.globalReducer.loadingReducer.isLoading
  }),
  { getPaymentStatus, setPaymentStatus, postToChannel, signInAction }
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect
)(PaymentConfirmation);
