// import TransactionDetails from "../../containers/TransactionDetails";
// import RequestATSDetails from "../../containers/RequestATSDetails";
// import ContentFeed from "../../containers/ContentFeed";
// import ContentFeedList from "../../containers/ContentFeedList";
// import ClientDetail from "../../containers/ClientDetail";
// import NewFund from "../../containers/FundDetails";
// import FundList from "../../containers/FundList";

import BankList from "../../containers/CPG/BankList";
import PaymentConfirmation from "../../containers/CPG/PaymentConfirmation";
import PaymentStatus from "../../containers/CPG/PaymentStatus";
import TempPaymentStatus from "../../containers/CPG/TempPaymentStatus";
import RspStatus from "../../containers/CPG/RSPStatus";
import Popup from "../Popup";
//import ProductList from "../../containers/CPG/ProductList";

const routes = [
  {
    path: "/",
    name: "CPG",
    component: PaymentConfirmation,
    exact: true
  },
  {
    path: "/banklist",
    name: "BankList",
    component: BankList,
    exact: true
  },
  {
    path: "/paymentstatus",
    name: "PaymentStatus",
    component: PaymentStatus,
    exact: true
  },

  {
    path: "/temppaymentstatus",
    name: "TempPaymentStatus",
    component: TempPaymentStatus,
    exact: true
  },
  {
    path: "/rspstatus",
    name: "RspStatus",
    component: RspStatus,
    exact: true
  },

  {
    path: "/popup",
    name: "Popup",
    component: Popup,
    exact: true
  }
  // {
  //   path: "/productlist",
  //   name: "productList",
  //   component: ProductList,
  //   exact: true
  // }
];

export default routes;
