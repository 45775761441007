import {
  GET_FPX_BANK_LIST,
  SET_FPX_BANK_LIST,
  SET_FPX_LOADING,
  SET_SELECTED_BANK,
  SET_AMOUNT,
  POST_FPX_PAYMENT,
  SET_PAYMENT_SUCCESS,
  SET_SUCCESS,
  CREATE_SIGNATURE,
  POST_PAYMENT_REQUEST,
  SET_COSTUMER_DATA,
  SET_TRX_REF_NO,
  SET_PAY_CAT,
  SET_FPX_ONLINE,
  GET_PAYMENT_STATUS,
  SET_PAYMENT_STATUS,
  GET_RSP_STATUS,
  SET_RSP_STATUS,
  POST_TO_CHANNEL,
  GET_FEE_BY_PAYCAT,
  SET_FEE,
  SET_CANCELED_DATA,
  SET_INVOICE_NO,
  SET_PAYMENT_CHANNEL,
  E_WALLET_PAY,
  E_WALLET_PAY_REQ,
  SET_CHECKOUT_LOADING
} from "./constant";

export const getBankList = callback => {
  return {
    type: GET_FPX_BANK_LIST,
    callback
  };
};

export const eWalletPay = (payload, postInfo) => {
  return {
    type: E_WALLET_PAY,
    payload,
    postInfo
  };
};

// export const eWalletPayreq = payload => {
//   return {
//     type: E_WALLET_PAY_REQ,
//     payload
//   };
// };

export const setBankListToReducer = payload => {
  return {
    type: SET_FPX_BANK_LIST,
    payload
  };
};

export const setFpxLoading = payload => {
  return {
    type: SET_FPX_LOADING,
    payload
  };
};

export const setSelectedBank = payload => {
  return {
    type: SET_SELECTED_BANK,
    payload
  };
};

export const setUserAmount = payload => {
  return {
    type: SET_AMOUNT,
    payload
  };
};

export const postPaymentRequest = payload => {
  return {
    type: POST_FPX_PAYMENT,
    payload
  };
};

export const setPaymentSuccess = payload => {
  return {
    type: SET_PAYMENT_SUCCESS,
    payload
  };
};

export const setPaymentChannel = payload => {
  return {
    type: SET_PAYMENT_CHANNEL,
    payload
  };
};

export const setSuccess = payload => {
  return {
    type: SET_SUCCESS,
    payload
  };
};

export const paymentRequest = payload => {
  return {
    type: POST_PAYMENT_REQUEST,
    payload
  };
};

export const createSignature = payload => {
  return {
    type: CREATE_SIGNATURE
  };
};

export const setCostumerData = payload => {
  return {
    type: SET_COSTUMER_DATA,
    payload
  };
};

export const setTrxRefNo = payload => {
  return {
    type: SET_TRX_REF_NO,
    payload
  };
};

export const setPayCat = payload => {
  return {
    type: SET_PAY_CAT,
    payload
  };
};

export const setIsFPXOnline = payload => ({
  type: SET_FPX_ONLINE,
  payload
});

export const getPaymentStatus = payload => {
  return {
    type: GET_PAYMENT_STATUS,
    payload
  };
};

export const setPaymentStatus = payload => {
  return {
    type: SET_PAYMENT_STATUS,
    payload
  };
};

export const postToChannel = payload => {
  return {
    type: POST_TO_CHANNEL,
    payload
  };
};

export const getFeeByPayCat = payload => {
  return {
    type: GET_FEE_BY_PAYCAT,
    payload
  };
};

export const setFee = payload => {
  return {
    type: SET_FEE,
    payload
  };
};

export const setCanceledData = payload => {
  return {
    type: SET_CANCELED_DATA,
    payload
  };
};

export const setInvoiceNo = payload => {
  return {
    type: SET_INVOICE_NO,
    payload
  };
};

export const getRspStatus = payload => {
  return {
    type: GET_RSP_STATUS,
    payload
  };
};

export const setRspStatus = payload => {
  return {
    type: SET_RSP_STATUS,
    payload
  };
};

export const setCheckoutLoading = payload => {
  return {
    type: SET_CHECKOUT_LOADING,
    payload
  };
};
