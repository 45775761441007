/**
 *
 * Routes
 *
 */

import React, { Fragment } from "react";
import { Route, withRouter } from "react-router-dom";
// import { Breadcrumbs } from "../../components/BreadCrumbs";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import {
  signOutAction,
  resetFailedRequestProps
} from "../../containers/Authentication/redux/actions";
import { stopLoading } from "../../globalRedux/actions";
import RoutesList from "./Routes";
import "./style.scss";
import "./spacing.scss";
import cogoToast from "cogo-toast";
// FPX

export class Routes extends React.Component {
  constructor(props) {
    super(props);
    if (window.performance) {
      if (performance.navigation.type === 1) {
        this.props.stopLoading();
        this.props.resetFailedRequestProps();
      }
    }
    this.invalidTokenListener = null;
  }
  state = {
    auth: false
  };

  static getDerivedStateFromProps(props) {
    if (props.auth.accessToken && !props.globalProps.loadingReducer.isLoading) {
      return {
        auth: true
      };
    }

    return {};
  }

  static defaultProps = {
    auth: "",
    location: {}
  };

  static propTypes = {
    auth: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    location: PropTypes.object
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    this.invalidTokenListener = window.eventEmitter.addListener(
      "invalid_token",
      () => {
        if (this.props.location.pathname !== "/") {
          if (this.props.location.pathname !== "/rspstatus") {
            this.props.history.push("/");
            //cogoToast.error("Your token has expired, please login again");
            localStorage.clear();
            this.props.signOutAction();
            this.setState({
              auth: false
            });
          }
        }
      }
    );
  }

  componentWillUnmount() {
    this.invalidTokenListener.remove();
  }

  renderRouts = () => {
    return RoutesList.map(({ exact, path, component }, i) => {
      // eslint-disable-next-line no-unused-vars
      let redirectPath = "/";
      if (this.props.location.pathname === "/") {
        if (this.props.location.search) {
          const reg = new RegExp("[?&]redirect=([^&#]*)", "i");
          const string = reg.exec(this.props.location.search);
          if (string) {
            if (string.length > 1) {
              redirectPath = string[1];
            }
          }
        }
      }
      return (
        <Fragment key={`Route-${i + 1}`}>
          <Route
            exact={exact}
            path={path}
            component={component}
            key={`Route-${i + 1}`}
          />
        </Fragment>
      );
    });
  };

  handleSignOut = () => {
    this.props.signOutAction();
    this.props.history.push("/");
    this.setState({
      auth: false
    });
  };

  render() {
    // const { selectedObject } = this.props;
    return <Fragment>{this.renderRouts()}</Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    clients: state.clientReducer,
    selectedObject: state.breadCrumbsReducer,
    globalProps: state.globalReducer
  };
};
const mapDispatchToProps = {
  signOutAction,
  stopLoading,
  resetFailedRequestProps
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
export default compose(
  withRouter,
  withConnect
)(Routes);
