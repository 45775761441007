import {
  put,
  call,
  all,
  takeLatest,
  takeEvery,
  select
} from "redux-saga/effects";
import * as endpoints from "../../../utils/endpoints";
import * as api from "../../../utils/api";
import * as actions from "./actions";
import { startLoading, stopLoading } from "../../../globalRedux/actions";
import { signIn as signInConstant } from "../../../globalRedux/constants/loader";
import { showLoader, hideLoader } from "../../../store/actions/loader";
import { API_URL } from "../../../utils/axios";
import cogoToast from "cogo-toast";
import {
  setCostumerData,
  setPayCat,
  setPaymentStatus,
  setPaymentChannel,
  getRspStatus,
  setFpxLoading
} from "../../CPG/redux/action";
import { sagaGetFee } from "../../CPG/redux/saga";

const getToken = state => state.auth.accessToken;

function* signIn(action) {
  try {
    yield put(setFpxLoading(true));
    yield put(startLoading());
    yield put(showLoader(signInConstant));

    const { invoiceNo, signature, trxRefNumber, checkFrom } = action.payload;

    const payloadSignin =
      checkFrom === "NormalCashIn"
        ? { invoiceNo, signature }
        : { trxRefNo: trxRefNumber, signature };

    const response = yield call(
      api.post,
      endpoints.SIGN_IN_ENDPOINT,
      payloadSignin
    );
    const responseSignin = { ...response.data };

    if (responseSignin.errorCode) {
      yield put(
        actions.userSignedIn({
          accessToken: null,
          refreshToken: null,
          tokenCreatedAt: null
        })
      );
      cogoToast.error("something went wrong!");
      throw new Error(responseSignin);
    }

    yield put(actions.userSignedIn(responseSignin));

    if (checkFrom === "NormalCashIn") {
      const custData = yield call(
        api.get,
        `${API_URL.ppg}/invoice/${invoiceNo}`
      );
      const { data } = custData;

      yield put(setCostumerData(data));

      const payCat = yield call(
        api.get,
        `${API_URL.ppg}/payment-channel/${data.customerChannelCode}`
      );
      const channelCode = {
        channelCode1: payCat.data[0].payCatCd,
        channelCode2: data.customerChannelCode
      };

      yield call(sagaGetFee, channelCode);

      yield put(setPaymentChannel(payCat.data));
      yield put(setPayCat(payCat.data[0]));
      yield put(setPaymentStatus({}));
    } else {
      yield put(getRspStatus(trxRefNumber));
    }
  } catch (error) {
    yield put(setCostumerData({ fee: 0, amount: 0 }));
    yield put(actions.signInRequestFailedAction());
  } finally {
    yield put(setFpxLoading(false));
    yield put(hideLoader(signInConstant));
    yield put(stopLoading());
  }
}

function* signUp({ payload }) {
  try {
    const response = yield call(api.post, endpoints.SIGN_UP_ENDPOINT, payload);
    yield put(actions.userSignedIn(response.data));
    yield call(setAccessTokenToHeader);
  } catch (error) {
    //console.log("error: ", error.response);
    yield put(actions.signUpRequestFailedAction());
  }
}

function* signOut() {
  yield put(actions.userSignedOut());
  yield call(api.resetAuthHeader);
}

function* testAuth() {
  try {
    const token = yield call(setAccessTokenToHeader);
    if (token) {
      const response = yield call(api.get, endpoints.TEST_AUTH_ENDPOINT);
      yield put(actions.userSignedIn(response.data));
    }
  } catch (error) {
    yield put(actions.testAuthRequestFailedAction());
  }
}

function* setAccessTokenToHeader() {
  const token = yield select(getToken);
  yield call(api.setAuthHeader, token);
  return token;
}

export default function* authSagas() {
  yield all([
    takeLatest(actions.signInAction, signIn),
    // takeLatest(actions.FpxSignInAction, fpxSignin),
    takeLatest(actions.signUpAction, signUp),
    takeLatest(actions.signOutAction, signOut),
    takeEvery(actions.testAuthAction, testAuth)
  ]);
}
