/*
 *
 * LanguageProvider reducer
 *
 */

import {
  DEFAULT_LOCALE,
  EN_LOCALE,
  THAI_LOCALE,
  CHANGE_LOCALE
} from "./constants"; // eslint-disable-line

const initialState = {
  locale: DEFAULT_LOCALE,
  languages: [EN_LOCALE, THAI_LOCALE]
};

function languageProviderReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: action.locale
      };
    default:
      return state;
  }
}

export default languageProviderReducer;
