import { createSelector } from "reselect";

/**
 * Direct selector to the languageToggle state domain
 */
const selectLanguage = state => {
  return state.language;
};
/**
 * Select the language locale
 */

export const makeSelectLocale = createSelector(
  selectLanguage,
  languageState => languageState.locale
);
