/**
 *
 * App.js
 *
 */

import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { BrowserRouter, Switch } from "react-router-dom";
import Routes from "../../components/Routes";
import { getUserConfirmation } from "./getUserConfirmation";

const App = () => {
  const GTM_ID = "GTM-WT5ZVQQ";
  const tagManagerArgs = {
    gtmId: GTM_ID
  };
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    ReactGA.initialize(GTM_ID);
  }, []);

  const theme = createTheme({
    palette: {
      primary: { main: "#004c97" }, // Purple and green play nicely together.
      secondary: { main: "#0076cf" } // This is just green.A700 as hex.
    },
    typography: { useNextVariants: true }
  });

  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter getUserConfirmation={getUserConfirmation}>
        <Switch>
          <Routes />
        </Switch>
      </BrowserRouter>
    </MuiThemeProvider>
  );
};
export default App;
