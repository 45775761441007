export function addAuthorizationHeader(options, token) {
  let config;
  const bearerToken = { headers: { Authorization: `Bearer ${token}` } };

  if (!options && !token) {
    return {};
  }
  if (!options && token) {
    config = bearerToken;
  }
  if (options && token) {
    if (options && options.headers && options.headers.Authorization) {
      return options;
    }
    config = {
      ...bearerToken,
      ...options,
      headers: {
        ...bearerToken.headers,
        ...options.headers
      }
    };
  }
  if (options && !token) {
    return options;
  }

  return config;
}
