import React from "react";
import MDSpinner from "react-md-spinner";
import "./style.scss";

const spinnerDefaultColor = "rgb(66, 165, 245)";

export default function Loader({ style }) {
  return (
    <div className="loaderStyles" style={{ ...style }}>
      <MDSpinner />
    </div>
  );
}

export function LoaderPhoto() {
  return (
    <div className="photoLoaderStyles">
      <MDSpinner size={30} singleColor={spinnerDefaultColor} />
    </div>
  );
}

export const DefaultLoader = props => (
  <MDSpinner singleColor={spinnerDefaultColor} {...props} />
);
