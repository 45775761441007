export const signIn = "signIn";
export const clientsTab = "clientsTab";
export const clientDetails = "clientDetails";
export const approveClient = "approveClient";
export const rejectClient = "rejectClient";
export const cancelActionUserStatus = "cancelActionUserStatus";
export const unlockUser = "unlockUser";
export const fundList = "fundList";
export const fundSave = "fundSave";

export const userStatus = {
  "approve-user": approveClient,
  "reject-user": rejectClient,
  "1": cancelActionUserStatus
};
