// SET
export const SET_FPX_BANK_LIST = "SET_FPX_BANK_LIST";
export const SET_FPX_LOADING = "SET_FPX_LOADING";
export const SET_SELECTED_BANK = "SET_SELECTED_BANK";
export const SET_AMOUNT = "SET_AMOUNT";
export const SET_SUCCESS = "SET_SUCCESS";
export const SET_COSTUMER_DATA = "SET_COSTUMER_DATA";
export const SET_TRX_REF_NO = "SET_TRX_REF_NO";
export const SET_PAY_CAT = "SET_PAY_CAT";
export const SET_PAYMENT_STATUS = "SET_PAYMENT_STATUS";
export const SET_PAYMENT_SUCCESS = "SET_PAYMENT_SUCCESS";
export const SET_PAYMENT_CHANNEL = "SET_PAYMENT_CHANNEL";
export const SET_FPX_ONLINE = "SET_FPX_ONLINE";
export const SET_FEE = "SET_FEE";
export const SET_CANCELED_DATA = "SET_CANCELED_DATA";
export const SET_INVOICE_NO = "SET_INVOICE_NO";
export const SET_RSP_STATUS = "SET_RSP_STATUS";

// GET
export const GET_FPX_BANK_LIST = "GET_FPX_BANK_LIST";
export const GET_PAYMENT_STATUS = "GET_PAYMENT_STATUS";
export const GET_FEE_BY_PAYCAT = "GET_FEE_BY_PAYCAT";
export const GET_RSP_STATUS = "GET_RSP_STATUS";

// POST
export const CREATE_SIGNATURE = "CREATE_SIGNATURE";
export const POST_FPX_PAYMENT = "POST_FPX_PAYMENT";
export const POST_PAYMENT_REQUEST = "POST_PAYMENT_REQUEST";
export const POST_TO_CHANNEL = "POST_TO_CHANNEL";
export const E_WALLET_PAY = "E_WALLET_PAY";
//export const E_WALLET_PAY_REQ = "E_WALLET_PAY_REQ";

export const SET_CHECKOUT_LOADING = "SET_CHECKOUT_LOADING";
