import { fork, all, take } from "redux-saga/effects";
import authSaga from "../../containers/Authentication/redux/saga";
// import { fundsWorkerSaga } from "../../containers/FundList/redux/saga";
import { FPXSaga } from "../../containers/CPG/redux/saga";

import { REHYDRATE } from "redux-persist/lib/constants";

export default function* rootSaga() {
  // Don't run sagas yet until redux-persist has reloaded everything into store
  // useful for when opening website again in a previously closed browser.
  // This should prevent sagas from trying to run with empty auth store.
  yield take(REHYDRATE);

  yield all([fork(authSaga), FPXSaga()]);
}
