import {
  SET_FPX_BANK_LIST,
  SET_FPX_LOADING,
  SET_SELECTED_BANK,
  SET_AMOUNT,
  SET_PAYMENT_SUCCESS,
  SET_COSTUMER_DATA,
  SET_TRX_REF_NO,
  SET_PAY_CAT,
  SET_FPX_ONLINE,
  SET_PAYMENT_STATUS,
  SET_FEE,
  SET_CANCELED_DATA,
  SET_INVOICE_NO,
  SET_PAYMENT_CHANNEL,
  SET_RSP_STATUS,
  SET_CHECKOUT_LOADING
} from "./constant";

const intialState = {
  bankList: [],
  loading: false,
  trxRefNo: "",
  selectedBank: {},
  paymentSuccess: {},
  paymentCategory: [],
  paymentStatus: {},
  paymentChannel: [],
  amount: "",
  success: false,
  isFPXOffline: false,
  custData: {},
  canceledData: {},
  invoiceNo: "",
  rspStatus: {},
  checkoutLoading: false
};

export default (state = intialState, { type, payload }) => {
  switch (type) {
    case SET_FPX_BANK_LIST:
      return {
        ...state,
        bankList: payload
      };
    case SET_PAYMENT_CHANNEL:
      return {
        ...state,
        paymentChannel: payload
      };
    case SET_FEE:
      return {
        ...state,
        custData: { ...state.custData, fee: payload }
      };
    case SET_PAYMENT_STATUS:
      return {
        ...state,
        paymentStatus: payload
      };
    case SET_FPX_ONLINE:
      return {
        ...state,
        isFPXOffline: payload
      };
    case SET_FPX_LOADING:
      return {
        ...state,
        loading: payload
      };
    case SET_SELECTED_BANK:
      return {
        ...state,
        selectedBank: payload
      };
    case SET_AMOUNT:
      return {
        ...state,
        amount: payload
      };
    case SET_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentSuccess: payload
      };
    case SET_COSTUMER_DATA:
      return {
        ...state,
        custData: payload
      };
    case SET_TRX_REF_NO:
      return {
        ...state,
        trxRefNo: payload
      };
    case SET_PAY_CAT:
      return {
        ...state,
        paymentCategory: payload
      };
    case SET_CANCELED_DATA:
      return {
        ...state,
        canceledData: payload
      };

    case SET_INVOICE_NO:
      return {
        ...state,
        invoiceNo: payload
      };
    case SET_RSP_STATUS:
      return {
        ...state,
        rspStatus: payload
      };
    case SET_CHECKOUT_LOADING:
      return {
        ...state,
        checkoutLoading: payload
      };
    default:
      return state;
  }
};
