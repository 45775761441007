export const colors = {
  white: "#ffffff",
  bagGrey: "#f1f1f1",
  redButtonColor: "#b10202",
  redColor: "#e6233e",
  blueLink: "#ef252e",
  greenLink: "#13ce95",
  yellow: "#e5af09",
  greyBorder: "#d7d7d7",
  greyColor: "#737373",
  black: "#000000",
  darkColor: "#4a4a4a",
  greyColorText: "#484848",
  placeholderColor: "#cfcfcf",
  searchBorder: "#f1f1f1",
  inactiveValue: "#aaaaaa",
  inactivePlaceholder: "#f7f7f7"
};

export const text = {
  color: {
    dark: "#000",
    grey: "#787878",
    greyText: "#1d1d1d",
    purple: "#3d084a",
    white: "#fff",
    blue: "#004c97",
    darkBlue: "#084c95"
  },
  fontFamily: {
    primary: "PSL-Norm",
    primaryMedium: "PSL-Med",
    primaryBold: "PSL-Bold"
  },
  fontSize: {
    large: 24,
    small: 22,
    mobil: 18
  }
};
