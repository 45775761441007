import { all, call, put, select, takeLatest } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  setBankListToReducer,
  setFee,
  setFpxLoading,
  setIsFPXOnline,
  setPaymentStatus,
  setPaymentSuccess,
  setRspStatus,
  setCheckoutLoading
} from "./action";
import {
  E_WALLET_PAY,
  GET_FEE_BY_PAYCAT,
  GET_FPX_BANK_LIST,
  GET_PAYMENT_STATUS,
  GET_RSP_STATUS,
  POST_FPX_PAYMENT,
  POST_PAYMENT_REQUEST,
  POST_TO_CHANNEL
} from "./constant";

import moment from "moment";
import { API_URL } from "../../../utils/axios";
const EINVESTOR = "EINVESTOR";
function sleep(ms = 1000) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function* sagaGetBankList({ paymentCat, callback = () => {} }) {
  try {
    const payCat = yield select(
      state => state.FPXReducer.paymentCategory.payCatCd
    ); // payment category such as fpx, tng
    const customerCH = yield select(
      state => state.FPXReducer.paymentCategory.customerChannelCode
    ); // customer channelcode is b2c or mp
    let isBuy = 1;
    if (customerCH.toLowerCase() === EINVESTOR.toLowerCase()) {
      isBuy = 0;
    } // the fees is 0.55
    yield put(setFpxLoading(true));
    const res = yield call(
      api.get,
      `/payment/payment-type-cat/${payCat}?channelCd=${customerCH}&isBuy=${isBuy}`
    ); //api for  payment type based on the fees
    const { data } = res;
    if (res.status === 200) {
      const listActive = data.filter(value => value.bankSts === "A"); // the status of the bank active or not active

      if (!listActive.length) {
        yield put(setIsFPXOnline(true)); // if the payment type is active the loading page will appear
      } else {
        yield put(setIsFPXOnline(false)); // if the payment type is not active the loading page will not appear
      }
      yield put(setBankListToReducer(data));
    }
    callback();
  } catch (error) {
    yield put(setFpxLoading(false));
  } finally {
    yield put(setFpxLoading(false));
  }
}

function* checkoutPayment({ payload }) {
  try {
    yield put(setCheckoutLoading(true));
    yield put(setFpxLoading(true));
    const res = yield call(api.post, "/pay", payload); // the api for payment pay

    const { fpxPayment } = res.data;

    if (res.status === 200) {
      yield put(setPaymentSuccess(fpxPayment));
      const myForm = document.createElement("form");
      myForm.method = "POST";
      myForm.action = fpxPayment.fpx_url;
      delete fpxPayment.fpx_url;
      for (const key in fpxPayment) {
        if (Object.hasOwnProperty.call(fpxPayment, key)) {
          const element = fpxPayment[key];
          const myInput = document.createElement("input");
          myInput.type = "hidden";
          myInput.name = key;
          myInput.value = element;
          myForm.appendChild(myInput);
        }
      }
      document.body.appendChild(myForm);
      myForm.submit();
    }
  } catch (error) {
    yield put(setCheckoutLoading(false));
    console.log(error);
  } finally {
    yield put(setFpxLoading(false));
  }
}

function* sagaPaymentRequest({ payload }) {
  try {
    yield put(setFpxLoading(true));
    const {
      customerChannelCode,
      trxRefNo,
      amount,
      ccy,
      name,
      email,
      cifID
    } = payload;
    const res = yield call(
      api.post,
      `${API_URL.ppg}/payment/generate`, // api for payment generate
      payload
    );
    const { data } = res;

    const dummy = {
      customerChannelCode,
      payCatCd: "FPX",
      trxRefNo,
      payTypeCd: "",
      amount,
      ccy,
      cifID,
      refId: "Principal ASEAN Dynamic Fund",
      name,
      email,
      lang: "en",
      signature: data.signature,
      resUrl: "http://localhost:4400/paymentstatus",
      backUrl: "https://c81d-103-163-39-24.ngrok.io/api/v1/test/backUrl"
    }; //dummy for fpx payment

    const myForm = document.createElement("form");
    myForm.method = "POST";
    myForm.id = "Payform";
    //myForm.action = `https://paysit.principal.com.my/api/v1/entry`;
    myForm.action = `${API_URL.ppg}/entry`;
    //myForm.action = `https://payuat.principal.com.my/api/v1/entry`;

    for (const key in dummy) {
      if (Object.hasOwnProperty.call(dummy, key)) {
        const element = dummy[key];
        const myInput = document.createElement("input");
        myInput.type = "hidden";
        myInput.name = key;
        myInput.id = key;
        myInput.value = element;
        myForm.appendChild(myInput);
      }
    }
    document.body.appendChild(myForm);
    myForm.submit();
  } catch (error) {
    console.log(error);
  } finally {
    yield put(setFpxLoading(false));
  }
}

function* sagaGetPaymentStatus({ payload }) {
  try {
    yield put(setFpxLoading(true));
    const token = yield select(state => state.auth.accessToken); // access token for

    const res = yield call(api.get, `/invoice/${payload}/summary`, {
      headers: { Authorization: `bearer ${token}` }
    }); // api for payment status will be taken by the invoice number

    yield put(setPaymentStatus(res.data));
    if (res.data.status >= 5) {
      // payment status is greter then 5 it will redirect to invoice page
      yield put(setFpxLoading(false));
    } else {
      // if the cash in method fpx or e-wallet dont have response from the call back or smaller then payment status it will remain the loading page
      yield sleep(1000);
      yield put(setFpxLoading(true));
    }
  } catch (error) {
    console.log(error);
  } finally {
  }
}

function* sagaRSPPaymentStatus({ payload }) {
  try {
    yield put(setFpxLoading(true));
    const res = yield call(api.get, `/rsp/${payload}/summary`);
    yield put(setRspStatus(res.data));
    if (res.data.status >= 5) {
      yield put(setFpxLoading(false));
    } else {
      yield put(setFpxLoading(true));
    }
  } catch (error) {
    console.log(error);
  }
}
// eslint-disable-next-line require-yield
function* postToChannel({ payload }) {
  try {
    const data = {
      customerChannelCode: payload.customerChannelCode,
      trxRefNo: payload.trxRefNo,
      total: payload.total,
      ccy: payload.ccy,
      pgRefNo: payload.pgRefNo,
      responseDesc: payload.responseDesc,
      bankId: payload.bankId,
      bankName: payload.bankName,
      lang: payload.lang,
      signature: payload.signature,
      status: payload.status,
      trxDate: moment(payload.trxDate)
        .utcOffset(8)
        .format("YYYY-MM-DD")
    };

    const myForm = document.createElement("form");
    myForm.method = "POST";
    myForm.id = "Payform";
    myForm.action = payload.resUrl;

    const myInput = document.createElement("input");
    myInput.type = "hidden";
    myInput.name = `pgResponse`;
    myInput.id = "pgResponse";
    myInput.value = JSON.stringify(data);
    myForm.appendChild(myInput);
    document.body.appendChild(myForm);
    myForm.submit();
  } catch (error) {
    console.log(error);
  }
}

export function* sagaGetFee(payload) {
  try {
    let newObj = payload;
    if (payload.type !== undefined) {
      newObj = payload.payload;
    }

    let isBuy = 1;
    let isSell = 1;

    if (
      newObj.channelCode2.toLowerCase() === EINVESTOR.toLowerCase() &&
      newObj.channelCode1 === "FPX"
    ) {
      isBuy = 0;
      isSell = 0;
    }
    if (
      newObj.channelCode2 === "Mini Program" &&
      newObj.channelCode1 === "FPX"
    ) {
      isBuy = 1;
      isSell = 0;
    }
    if (
      newObj.channelCode2 === "Mini Program" &&
      newObj.channelCode1 === "TnG"
    ) {
      isBuy = 1;
      isSell = 0;
    }
    // the condition for each payment method is different
    //Einvestor = 0.55,  Miniprogram (FPX) = 0.50 and Miniprogram (TNG) = 0.00
    const res = yield call(
      api.get,
      `/payment-categories/${
        newObj.channelCode1
      }/fee?isBuy=${isBuy}&isSell=${isSell}&channelCd=${newObj.channelCode2}`
    );
    yield put(setFee(res.data.fee));
  } catch (error) {
    console.log(error);
  }
}

// payment e-wallet
function* sagaEwalletPay({ payload, postInfo }) {
  const postInfoPayload = postInfo; // the information in paymentconfirmation details of the payment
  const customerChannelCode = yield select(
    state => state.FPXReducer.paymentCategory.customerChannelCode
  );
  try {
    const res2 = yield call(
      api.get,
      `/payment/payment-type-cat/TnG?isBuy=1&channelCd=${customerChannelCode}`
    ); // api with the parameter with isbuy and customer channel code

    if (res2.status === 200) {
      // the api response 200 will check the fees and payment type
      let tngVal = {
        fee: res2.data[0].fee,
        feeCcy: res2.data[0].feeCcy,
        payCatCd: res2.data[0].payCatCd,
        payTypeCd: res2.data[0].code
        // custFee:res2.data[0].custFee,
        // custFeeCcy:"MYR",
        // absFeeCcy:"MYR",
        // absFee:res2.data[0].absFee,
        // refundFee:res2.data[0].refundFee,
      };
      const returnedTarget = Object.assign(postInfoPayload, tngVal);
      const tngRes = yield call(api.post, "/pay", returnedTarget);
    }

    const res = yield call(
      api.post,
      `${API_URL.miniProgram}/payments/pay`,
      payload
    ); //api for tngd pay with tradepay

    if (res.status === 200 && typeof window.my !== "undefined") {
      window.my.tradePay({
        paymentUrl: res.data.actionForm.redirectionUrl,
        success: res => {
          switch (res.resultCode) {
            // payment cancel or failed
            case "6001":
            case "4000":
              window.location.href = `/temppaymentstatus?trxRefNo=${
                postInfo.trxRefNo
              }`;
              break;
            case "8000":
              window.location.href = `/popup`;
              break;
            default:
              break;
          }
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* FPXSaga() {
  yield all([
    takeLatest(GET_FPX_BANK_LIST, sagaGetBankList),
    takeLatest(POST_FPX_PAYMENT, checkoutPayment),
    takeLatest(POST_PAYMENT_REQUEST, sagaPaymentRequest),
    takeLatest(GET_PAYMENT_STATUS, sagaGetPaymentStatus),
    takeLatest(POST_TO_CHANNEL, postToChannel),
    takeLatest(GET_FEE_BY_PAYCAT, sagaGetFee),
    takeLatest(E_WALLET_PAY, sagaEwalletPay),
    takeLatest(GET_RSP_STATUS, sagaRSPPaymentStatus)
  ]);
}
