import React from "react";
import "./style.scss";
import MobileHeader from "../Header/MobileHeader";
import Button from "@material-ui/core/Button";

const index = props => {
  const { paymentCategory } = props;
  return (
    <div className="mobile-header">
      <MobileHeader title="Payment Gateway" />
      <div
        //style={{  margin: 24px 0 16px; }}
        className="titleFailed"
      >
        Cash In Progress
      </div>
      <div className="text">
        We Are Processing Your Payment. We will notify you once the payment is
        complete
      </div>

      <Button
        className="button"
        variant="contained"
        color="secondary"
        style={{ marginBottom: 24 }}
        onClick={() =>
          // window.location.replace("https://mpuat.principal.com.my/dashboard")
          window.location.replace("https://secure06.principal.com.my/dashboard")
        }
      >
        Done
      </Button>
    </div>
  );
};

export default index;
