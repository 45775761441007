import { put as sagaPut, call, select } from "redux-saga/effects";
import cogoToast from "cogo-toast";
import api, { setAuthHeader, resetAuthHeader } from "../utils/axios";
import { REFRESH_ACCESS_TOKEN_ENPOINT } from "./endpoints";
import * as actions from "../containers/Authentication/redux/actions";
import { addAuthorizationHeader } from "./performAuthorized";
const getRefreshToken = state => state.auth.refreshToken;

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        window.eventEmitter.emit("invalid_token");
      } else {
        const { data = {} } = error.response;
        if (data) {
          if (data.message) {
            cogoToast.error(data.message);
          }
        }
      }
    }
    return Promise.reject(error.response);
  }
);

export function* get(endpoint, options) {
  try {
    let token = yield select(state => state.auth.accessToken);
    const config = addAuthorizationHeader(options, token);
    return yield call(api.get, endpoint, config);
  } catch (error) {
    return yield call(checkForTokenError, error, retryRequest, "accessToken");
  }
}

export function* post(endpoint, data, options) {
  try {
    let token = yield select(state => state.auth.accessToken);
    const config = addAuthorizationHeader(options, token);
    return yield call(api.post, endpoint, data, config);
  } catch (error) {
    return yield call(checkForTokenError, error, retryRequest, "accessToken");
  }
}

export function* put(endpoint, data, options) {
  try {
    let token = yield select(state => state.auth.accessToken);
    const config = addAuthorizationHeader(options, token);
    return yield call(api.put, endpoint, data, config);
  } catch (error) {
    return yield call(checkForTokenError, error, retryRequest, "accessToken");
  }
}
export function* patch(endpoint, data, options) {
  try {
    let token = yield select(state => state.auth.accessToken);
    const config = addAuthorizationHeader(options, token);
    return yield call(api.patch, endpoint, data, config);
  } catch (error) {
    return yield call(checkForTokenError, error, retryRequest, "accessToken");
  }
}

export function* del(endpoint, data, options) {
  try {
    let token = yield select(state => state.auth.accessToken);
    const config = addAuthorizationHeader(options, token);
    return yield call(api.delete, endpoint, data, config);
  } catch (error) {
    return yield call(checkForTokenError, error, retryRequest, "accessToken");
  }
}

function* retryRequest(error) {
  const newToken = yield call(refreshAccessToken);
  const newError = { ...error };
  newError.config.headers.Authorization = `Bearer ${newToken}`;
  return yield call(api.request, newError.config);
}

function checkForTokenError(error, onAccessTokeErrorCallback, paramToCheck) {
  // if (error.response) {
  //   console.log(error.response);
  //   const accessTokenError = error.response.data.find(
  //     e => e.param === paramToCheck
  //   );
  //   if (accessTokenError) {
  //     return yield call(onAccessTokeErrorCallback, error);
  //   }
  //
  //   throw error.response;
  // } else if (error.request) {
  //   throw createSimpleErrorObject("Server does not respond");
  // }
  //
  // throw createSimpleErrorObject("Please, check your internet connection");
  throw createSimpleErrorObject(error);
}

function* refreshAccessToken() {
  try {
    const refreshToken = yield select(getRefreshToken);
    const response = yield call(api.post, REFRESH_ACCESS_TOKEN_ENPOINT, {
      refreshToken
    });
    setAuthHeader(response.data.accessToken);
    yield sagaPut(actions.accessTokenUpdatedAction(response.data));
    return response.data.accessToken;
  } catch (error) {
    return yield call(
      checkForTokenError,
      error,
      onRefreshTokenError,
      "refreshToken"
    );
  }
}

function* onRefreshTokenError(error) {
  yield sagaPut(actions.signOutAction());
  throw error;
}

function createSimpleErrorObject(error) {
  return {
    response: error.response.data
  };
}

export { resetAuthHeader, setAuthHeader };
