export default {
  "app.components.SideMenu.LeftMenu.clients": "clients- TH",
  "app.components.SideMenu.LeftMenu.fundList": "fund list - TH",
  "app.components.SideMenu.LeftMenu.transaction": "transaction - TH",
  "app.components.SideMenu.LeftMenu.contentFeed": "content feed- TH",
  "app.components.SideMenu.LeftMenu.transactionDetails":
    "Transaction details- TH",
  "app.components.SideMenu.LeftMenu.requestATSDetails":
    "Transaction details- TH",
  "app.container.ClientDetail.Conservative": "อนุรักษ์นิยม (ระดับ 1)",
  "app.container.ClientDetail.ModerateConservative": "ยับยั้งปานกลาง (ระดับ 2)",
  "app.container.ClientDetail.Moderate": "ปานกลาง (ระดับ 3)",
  "app.container.ClientDetail.ModerateAggressive": "ปานกลางก้าวร้าว (ระดับ 4)",
  "app.container.ClientDetail.Aggressive5": "ก้าวร้าว (ระดับ 5)",
  "app.container.ClientDetail.Aggressive6": "ก้าวร้าว (ระดับ 6)"
};
