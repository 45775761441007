import { createReducer } from "redux-act";
import { stopLoading, startLoading } from "../actions";

const initialState = {
  isLoading: false
};
export const loadingReducer = createReducer(
  {
    [startLoading]: state => {
      return {
        ...state,
        isLoading: true
      };
    },
    [stopLoading]: state => {
      return {
        ...state,
        isLoading: false
      };
    }
  },
  initialState
);
