import { createReducer } from "redux-act";
import {
  showLoader,
  hideLoader,
  showLoaderWithMeta,
  hideLoaderWithMeta
} from "../actions/loader";

const initialState = {};

const loaderReducer = createReducer(
  {
    [hideLoader]: (state, payload) => ({
      ...state,
      [payload]: false
    }),
    [showLoader]: (state, payload) => ({
      ...state,
      [payload]: true
    }),
    [showLoaderWithMeta]: (state, payload) => ({
      ...state,
      [payload.loader]: {
        ...payload.loader,
        [payload.meta]: true
      }
    }),
    [hideLoaderWithMeta]: (state, payload) => ({
      ...state,
      [payload.loader]: {
        ...payload.loader,
        [payload.meta]: false
      }
    })
  },
  initialState
);

export default loaderReducer;
