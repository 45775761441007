import { createGlobalStyle } from "styled-components";

import BoldTTF from "./assets/fonts/Lato-Bold.ttf";
import BoldWOFF from "./assets/fonts/Lato-Bold.woff";
import BoldWOFF2 from "./assets/fonts/Lato-Bold.woff2";
import BoldEOT from "./assets/fonts/Lato-Bold.eot";
import BoldOTF from "./assets/fonts/Lato-Bold.otf";
import Regular from "./assets/fonts/Lato-Regular.ttf";
import SemiBold from "./assets/fonts/Lato-SemiBold.ttf";
import FSElliot from "./assets/fonts/FS-Elliot-Pro-Regular.woff";
import FSElliotBold from "./assets/fonts/FS-Elliot-Pro-Bold.woff";

import UbuntuRegular from "./assets/fonts/Ubuntu-Regular.ttf";

/* eslint no-unused-expressions: 0 */
const GlobalStyle = createGlobalStyle`
 
  @font-face {
    font-family: Lato;
    url(${Regular})  format('truetype');
    letter-spacing: 0.2px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: Lato-Med;
    url(${SemiBold})  format('truetype');
    letter-spacing: 0.2px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }
   @font-face {
    font-family: Lato-Bold;
    url(${BoldTTF})  format('truetype');
    url(${BoldWOFF})  format('woff');
    url(${BoldWOFF2})  format('woff2');
    url(${BoldEOT})  format('embedded-opentype');
    url(${BoldOTF})  format('woff2');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.2px;
  }
  @font-face {
    font-family: Ubuntu-Regular;
    url(${UbuntuRegular})  format('truetype');
    letter-spacing: 0.2px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }
  @font-face {
    font-family: FS-Elliot;
    src: local("FS-Elliot"), url(${FSElliot})  format('woff');
    letter-spacing: 0.2px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
    font-family: FS-Elliot-Bold;
    src: local("FS-Elliot-Bold"), url(${FSElliotBold})  format('woff');
    letter-spacing: 0.2px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
  }

  *{
    font-family: "FS-Elliot", sans-serif
  }
  
  body {
    font-family: "FS-Elliot", sans-serif;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
 
  // body.body-overflow-hidden {
  //   overflow-y: hidden;
  // }
  

  body.fontLoaded {
    font-family: "FS-Elliot", sans-serif;
  }

  #root {
    display: flex;
    position: relative;
    min-height:0vh;
    margin-top:40px;
    overflow-x: hidden;
    letter-spacing: 0.1px;
    font-family: "FS-Elliot", sans-serif;
    max-width : 480px;
    width : 480px;
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.4);
    -webkit-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.4);
  }
  @media screen and (max-width: 479px) and (min-width: 360px){
    #root{
      min-height:100vh;
      margin-top:0px;
    }
  }
  h2 {
          font-family: "FS-Elliot", sans-serif;
          font-weight: 400;
          font-size: 30px;
          line-height: 0.77;
          letter-spacing: 0.3px;
          text-align: center;
          color: $black;
          text-transform: uppercase;
        }


`;
export default GlobalStyle;
