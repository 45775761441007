const style = {
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingBottom: "4vh"
  },
  baseFont: {
    fontFamily: "FSElliot, sans-serif",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  App: {
    flexGrow: 1,
    //height: "100vh",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "4vh"
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#00a29b",
    fontFamily: "FS-Elliot-Bold, sans-serif"
  },
  titleCenter: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#00a29b",
    textAlign: "center",
    fontFamily: "FS-Elliot-Bold, sans-serif"
  },
  titleFailed: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#c00000",
    fontFamily: "FS-Elliot-Bold, sans-serif"
  },
  titleProgress: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#000000",
    fontFamily: "FS-Elliot-Bold, sans-serif"
  },
  centered: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
};

export default style;
